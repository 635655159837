@import '../../styles/variables.css';

.container {
  display: none;

  @media (--tablet-landscape-up) {
    display: block;
    margin: 0 0 0 24px;
  }
}

.btn {
  @mixin btn-reset;
  @mixin font-family-title;
  @mixin font-bold;
  font-size: 18px;
  line-height: 27px;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--header-ui-color);
  transition: color 0.2s;
}
